import React from 'react';
import MainFooter from './MainFooter';
import CopyrightBar from './CopyrightBar';

import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <MainFooter />
            <CopyrightBar />
        </footer>
    );
};

export default Footer;