import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';

import MainHeader from './components/layout/header/MainHeader';
import Footer from './components/layout/footer/Footer';
import SideDrawer from './components/layout/SideDrawer/SideDrawer';
import NavLinks from './components/layout/header/NavLinks';
import Home from './pages/home';
import LoadingSpinner from './components/UIComponents/LoadingSpinner/LoadingSpinner';

import './App.scss';
import * as actionCreators from './store/actions/index';

const About = React.lazy(() => import('./pages/about'));
const Careers = React.lazy(() => import('./pages/careers'));
const Contact = React.lazy(() => import('./pages/contact'));
const Events = React.lazy(() => import('./pages/events'));
const Faq = React.lazy(() => import('./pages/faq'));
const NewClient = React.lazy(() => import('./pages/newClient'));
const Services = React.lazy(() => import('./pages/services'));
const Testimonials = React.lazy(() => import('./pages/testimonials'));
const Resources = React.lazy(() => import('./pages/resources'));
const Document = React.lazy(() => import('./pages/document'));

const App = (props) => {
  let routes;

  routes = (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/about" exact>
        <About />
      </Route>

      <Route path="/services" exact>
        <Services />
      </Route>

      {/* <Route path="/about/insurance">
        <Insurance />
      </Route> */}

      <Route path="/about/faq">
        <Faq />
      </Route>

      <Route path="/testimonials">
        <Testimonials />
      </Route>

      <Route path="/careers">
        <Careers />
      </Route>

      <Route path="/contact">
        <Contact />
      </Route>

      <Route path="/events">
        <Events />
      </Route>

      <Route path="/resources" exact>
        <Resources />
      </Route>

      <Route path="/resources/document/:file">
        <Document />
      </Route>

      <Route path="/newclient">
        <NewClient />
      </Route>

      <Redirect to="/" />
    </Switch>
  );

  return (
    <React.Fragment>
      <Router>
        <MainHeader />
        <main>
          <SideDrawer show={props.sdr} onClick={props.onSideDrawerClose}>
            <nav className="mobile-nav">
              <NavLinks />
            </nav>
          </SideDrawer>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
        <Footer />
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sdr: state.sdr.isOpen,
    // ctr: state.ctr.counter,
    // res: state.res.results
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSideDrawerOpen: () => dispatch(actionCreators.openSideDrawer()),
    onSideDrawerClose: () => dispatch(actionCreators.closeSideDrawer()),
    // onIncrementCounter: () => dispatch(actionCreators.increment()),
    // onDecrementCounter: () => dispatch(actionCreators.decrement()),
    // onAdd: () => dispatch(actionCreators.add(5)),
    // onSubtract: () => dispatch(actionCreators.subtract(5)),
    // onStoreResult: (result) => dispatch(actionCreators.storeResult(result)),
    // onDeleteResult: (id) => dispatch(actionCreators.deleteResult(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
