import React from 'react';
//import LoadingSpinner from '../components/UIComponents/LoadingSpinner/LoadingSpinner';
import PageSection from '../components/layout/PageSection/PageSection';
import Button from '../components/UIComponents/Button/Button';
import Testimonials from '../components/UIComponents/TestimonialCarousel/Testimonials';
import InsuranceGrid from '../components/content/InsuranceGrid/InsuranceGrid';
import { useHistory } from 'react-router-dom';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import './page.scss';
import './home.scss';

const Home = (props) => {
  const history = useHistory();

  let testimonials = [
    {
      text: 'In my current role as a Supervisor of Special Education in a public school district, I cannot speak highly enough of the services provided by ABA Support Services, Inc......   ABA Support Services, Inc. has always worked together with our administration, staff and families to be sure student needs are addressed in consultation with our district and will consistently go above and beyond in a highly professional manner.',
    },
    {
      text: 'Therapists are great!  They are very important in our life. They are so wonderful.',
    },
    {
      text: 'Extremely satisfied and very happy with the services we are receiving from ABA Support Services. The staff is wonderful and commend the communication that all staff provides.',
    },
    {
      text: 'Very consistent and always communicates with us. Tell us every process and always answer questions. They repeat themselves and do not mind. Very satisfied, very good at what they do!',
    },
    {
      text: 'Extremely satisfied and very happy with the services we are receiving from ABA Support Services. The staff is wonderful and commend the communication that all staff provides.',
    },
    {
      text: 'We found Ms. Gable to be very conscientious and knowledgeable in ABA. She was able to quickly and accurately identify skill deficits and create an effective behavior plan, as well as. Teach us strategies to increase positive behavior. It is evident she is passionate about the work she does and cares',
    },
  ];
  return (
    <React.Fragment>
      {/* <LoadingSpinner asOverlay /> */}
      <section className="home-hero">
        <div className="home-hero__container">
          {/*<img src="/assets/images/aba-team-group.jpg" />*/}
          <img
              alt="ABA Support Services Team Group Photo"
              data-sizes="auto"
              data-srcset="/assets/images/aba-team-group-600.jpg 600w,
                /assets/images/aba-team-group-1200.jpg 1200w,
                /assets/images/aba-team-group.jpg 1800w"
              data-src="/assets/images/aba-team-group.jpg"
              className="lazyload"/>
          <div className="home-hero__overlay" />
          <div className="home-hero__text">
            <div>
              MAKING CONNECTIONS <span>TODAY</span> FOR A BETTER{' '}
              <span>TOMORROW</span>
            </div>
          </div>
        </div>
        <div className="home-hero__title">
          <div>
            making connections <span>TODAY</span> for a better{' '}
            <span>TOMORROW</span>
          </div>
        </div>
      </section>
      <PageSection section="introduction" boxed>
        <h3>Welcome! We'll try to answer all your questions.</h3>
      </PageSection>
      <PageSection section="who-we-are" boxed>
        <div className="page-section__container-grid">
          <div className="page-section__text">
            <h2>Who we are</h2>
            <p>
              We are experts in the field of Applied Behavior Analysis(ABA). We
              utilize our expertise to assist children with communication,
              behavioral, and socialization challenges, with autism and similar
              disorders.
            </p>
            <p>
              Our staff are constantly trained on the most current research from
              the experts themselves. By reviewing our training schedule, you
              can see the trainings our staff receives on research based
              interventions. Our facility also does not focus on one program but
              rather the focus is on the child and their response to learning.
              Each child is unique and requires a different approach. We pride
              ourselves on learning and meeting the needs of our clients.
            </p>
            <Button to="/about">Learn more</Button>
          </div>
          <div className="page-section__image">
            {/*<img src="/assets/images/who-we-are.jpg" alt="" />*/}
            <img
                alt="ABA Support Services Teamwork"
                data-sizes="auto"
                data-srcset="/assets/images/who-we-are-375.jpg 375w,
                  /assets/images/who-we-are.jpg 750w"
                data-src="/assets/images/who-we-are.jpg"
                className="lazyload"/>
          </div>
        </div>
      </PageSection>
      <PageSection section="what-we-do" boxed>
        <div className="page-section__container-grid page-section__even">
          <div className="page-section__text">
            <h2>What we do</h2>
            <p>
              Applied Behavior Analysis (ABA) is a therapy that focuses on
              improving specific behaviors. This includes improving social
              skills, communication skills, academic skills, adaptive skills,
              daily living skills, and job related skills. ABA therapy can be
              provided in a variety of settings, including: schools, homes,
              clinics, community settings, and workplaces. ABA is effective for
              children and adults with psychological disorders. Research and
              service outcomes have shown that consistent ABA can significantly
              improve behavior, communication skills, and social skills in
              addition to increasing independence.
            </p>
            <Button to="/services">Learn more</Button>
          </div>
          <div className="page-section__image">
            {/*<img src="/assets/images/what-we-do.jpg" alt="" />*/}
            <img
                alt="ABA Support Services - What we do"
                data-sizes="auto"
                data-srcset="/assets/images/who-we-are.jpg 500w,
                  /assets/images/what-we-do.jpg 640w,
                  /assets/images/what-we-do.jpg 1024w"
                data-src="/assets/images/what-we-do.jpg"
                className="lazyload"/>
          </div>
        </div>
      </PageSection>
      <section className="home-page__enroll">
        <div className="page-section__image-fullwidth">
          {/*<img src="/assets/enroll-now.jpg" alt="Enroll Now!" />*/}
          <img
              alt="Enroll Now!"
              data-sizes="auto"
              data-srcset="/assets/images/enroll-now.jpg 500w,
                  /assets/images/enroll-now.jpg 640w,
                  /assets/images/enroll-now.jpg 1024w"
              data-src="/assets/images/enroll-now.jpg"
              className="lazyload"/>
        </div>
        <div className="page-section__text-fullwidth">
          <p>
            MAKING CONNECTIONS <span>TODAY</span> FOR A BETTER{' '}
            <span>TOMORROW</span>
          </p>
          <Button size="big" onClick={() => history.push('/newclient')}>
            Enroll Now
          </Button>
        </div>
      </section>
      <PageSection section="how-can-we-help-you" boxed>
        <div className="page-section__container-grid">
          <div className="page-section__text">
            <h2>How can we help you?</h2>
            <p>
              We are experts in the field of Applied Behavior Analysis(ABA). We
              utilize our expertise to assist children with communication,
              behavioral, and socialization challenges, with autism and similar
              disorders.
            </p>
            <p>
              Our staff are constantly trained on the most current research from
              the experts themselves. By reviewing our training schedule, you
              can see the trainings our staff receives on research based
              interventions. Our facility also does not focus on one program but
              rather the focus is on the child and their response to learning.
              Each child is unique and requires a different approach. We pride
              ourselves on learning and meeting the needs of our clients.
            </p>
            <Button to="/services">Learn more</Button>
          </div>
          <div className="page-section__image">
            {/*<img src="/assets/images/how-can-we-help-you.jpg" alt="" />*/}
            <img
                alt="ABA Support Services - How can we help you"
                data-sizes="auto"
                data-srcset="/assets/images/how-can-we-help-you.jpg 500w,
                  /assets/images/how-can-we-help-you.jpg 640w,
                  /assets/images/how-can-we-help-you.jpg 1024w"
                data-src="/assets/images/how-can-we-help-you.jpg"
                className="lazyload"/>
          </div>
        </div>
      </PageSection>
      <section className="home-page__testimonials">
        <div className="home-page__testimonials-inner">
          <Testimonials testimonials={testimonials}></Testimonials>
        </div>
      </section>
      <PageSection section="careers" title="Are we hiring?" boxed>
        <div className="page-section__container-grid page-section__even">
          <div className="page-section__text">
            <h3>Yes, we are growing</h3>
            <p>
              ABA Support Services is a rapidly growing company. ABA Support
              Services is seeking new employees that have the same passion,
              drive, and commitment to helping our clients reach their full
              potential. We are looking for employees that can contribute ideas,
              experience, and passion to the operations of managed care and
              business. We value our employees in that they are the reason
              behind a companies’ success.
            </p>
            <p>
              Our employees are given RBT training to help them advance in their
              careers in order to provide the best quality care to our clients.
              ABA Support Services provides in house training with some of the
              leading experts in the field. We train our staff in multiple
              programs and to collaborate with many different related providers.
            </p>
            <p>
              If you feel that you fit the companies vision and core values, we
              would love to receive an application and discuss future employment
              further.
            </p>
            <Button href="https://recruiting.paylocity.com/recruiting/jobs/All/22a25901-25b9-4838-946f-b2ab99936073/ABA-Support-Services-LLC">
              View open positions
            </Button>
          </div>
          <div className="page-section__image">
            {/*<img src="/assets/images/are-you-hiring.jpg" alt="" />*/}
            <img
                alt="ABA Support Services - We Are Hiring"
                data-sizes="auto"
                data-srcset="/assets/images/are-you-hiring.jpg 500w,
                  /assets/images/are-you-hiring.jpg 640w,
                  /assets/images/are-you-hiring.jpg 1024w"
                data-src="/assets/images/are-you-hiring.jpg"
                className="lazyload"/>
          </div>
        </div>
      </PageSection>
      <PageSection
        section="insurance"
        title="Which insurance companies we currently accept?"
        boxed
      >
        <h3>We accept most insurance plans</h3>
        <InsuranceGrid />
        <div className="page-section__insurance-cta">
          Your insurance is not listed? Verify your insurance benefits today.
        </div>
        <Button to="/contact">Contact us</Button>
      </PageSection>

      {/* <LoadingSpinner /> */}
    </React.Fragment>
  );
};

export default Home;
