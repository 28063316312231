import React
//, {useRef} 
from 'react';
//import ReactDOM from 'react-dom';

import './Backdrop.scss';

const Backdrop = (props) => {
  // const backdropHook = useRef('backdrop-hook');
  return <div className="backdrop" onClick={props.onClick}></div>
  // return ReactDOM.createPortal(
  //   <div className="backdrop" onClick={props.onClick}></div>,
  //   document.getElementById('backdrop-hook')
  // );
};

export default Backdrop;