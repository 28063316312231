import React from 'react';

import './PageSection.scss';

const PageSection = (props) => {
  return (
    <section id={props.section} className="page-section__anchor">
      <div
        className={`page-section__content ${
          props.boxed ? 'page-section__content-boxed' : ''
        }`}
      >
        {props.title && <h2>{props.title}</h2>}
        {props.children}
      </div>
    </section>
  );
};

export default PageSection;
