import * as actionTypes from './actionTypes';

export const textInputValueEntered = (id, value) => {
    return {
        type: actionTypes.TEXT_INPUT_VALUE_CHANGE,
        id,
        value,
    }
}

export const textInputTouched = (id) => {
    return {
        type: actionTypes.TEXT_INPUT_TOUCH_CHANGE,
        id,
    }
}

export const checkboxValueChanged = (field, id) => {
    return {
        type: actionTypes.CHECKBOX_VALUE_CHANGE,
        field,
        id,
    }
}

export const dateValueEntered = (id, value) => {
    return {
        type: actionTypes.DATE_VALUE_CHANGE,
        id,
        value,
    }
}