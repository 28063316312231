import * as actionTypes from './actionTypes';

export const openSideDrawer = () => {
    return {
        type: actionTypes.OPEN_SIDE_DRAWER
    }
}

export const closeSideDrawer = () => {
    return {
        type: actionTypes.CLOSE_SIDE_DRAWER
    }
}