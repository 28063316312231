import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isOpen: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.OPEN_SIDE_DRAWER:
            return{
                isOpen: true
            }
        case actionTypes.CLOSE_SIDE_DRAWER:
            return{
                isOpen: false
            }
        default:
            return state;
    }
};

export default reducer;