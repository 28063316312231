import * as actionTypes from '../actions/actionTypes';

const initialState = {
    activeCounty: '',
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SET_ACTIVE_COUNTY: 
            return {
                ...state,
                activeCounty: action.value
            }
        default:
            return state;
    }
};

export default reducer;