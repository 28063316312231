import React, { useState } from 'react';
import { connect } from 'react-redux'

import NavLinks from './NavLinks';
import BackDrop from '../../UIComponents/Backdrop/Backdrop'

import { Avatar, Menu, MenuItem } from '@material-ui/core';
import './MainHeader.scss';
import './hamburger.scss'

import * as actionCreators from '../../../store/actions/index';

const MainHeader = props => {
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        //console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };
    const loginHandler = () => {
        handleProfileMenuClose();
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id='primary-account-menu'
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleProfileMenuClose}
            >
            <MenuItem onClick={loginHandler} key="login">Login / Register</MenuItem>
            {/* <MenuItem onClick={logoutHandler}>Logout</MenuItem> */}
        </Menu>
    );

    const toggleDrawerHandler = () => {
        if(props.sdr) props.onSideDrawerClose();
        else props.onSideDrawerOpen();
    }

    return (
        <React.Fragment>
            {props.sdr && (
                <BackDrop onClick={props.onSideDrawerClose} />
            )}

            

            <header className="main-header">
                <div className="main-header__burger">
                    <button className={`hamburger hamburger--spring ${props.sdr?'is-active':''}`} type="button" onClick={toggleDrawerHandler}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
                {/* <h1 className="main-navigation__title">
                    <Link to="/">Your Places</Link>
                </h1> */}
                <a href="/" className="main-header__brand">
                    <img src="/assets/logotype.png" alt="ABA Support Services" />
                </a>
                <div className="main-header_contact">
                    <a href='tel:+16103658373'><span className="material-icons">call</span> (610) 365-8373</a>
                    {/* <p><span class="material-icons">email</span> <a href="mailto:hireme@abasupportservices.com">hireme@abasupportservices.com</a></p> */}
                </div>
                <div className="main-header__user">
                    {/* <span className="material-icons">account_circle</span> */}
                    <Avatar
                        className="main-header__avatar"
                        // src={<span className="material-icons">account_circle</span>}
                        edge="end"
                        aria-label="account of current user"
                        aria-controls='primary-account-menu'
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        >
                    </Avatar>
                    {renderMenu}
                </div>
                <nav className="main-nav">
                    <NavLinks />
                </nav>   
            </header>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
      sdr: state.sdr.isOpen
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onSideDrawerOpen: () => dispatch(actionCreators.openSideDrawer()),
      onSideDrawerClose: () => dispatch(actionCreators.closeSideDrawer()),
    };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);