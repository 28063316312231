import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import Testimonial from './Testimonial';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Testimonials.scss';

const Testimonials = (props) => {
  let i = 0;
  return (
    <Carousel  infiniteLoop showThumbs={false} swipeable showStatus={false} >
      {/* autoPlay */}
        {props.testimonials.map(testimonial=><Testimonial text={testimonial.text} key={i++}></Testimonial>)}
                
    </Carousel>
  );
};

export default Testimonials;