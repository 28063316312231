import React
       /*  ,{ useContext }  */
      from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../UIComponents/Button/Button';

//import { AuthContext } from '../../context/auth-context';
import './NavLinks.scss';
//import Button from '../FormElements/Button';

const NavLinks = (props) => {
  //const auth = useContext(AuthContext);

  return (
    <ul className="nav__items">
        <li className="nav__item">
            <NavLink to="/" exact>Home</NavLink>
        </li>
        <li className="nav__item">
            <NavLink to="/about" exact>About us</NavLink>
        </li>
        <li className="nav__item">
            <NavLink to="/services" exact>Services</NavLink>
        </li>
        {/* <li className="nav__item">
          <NavLink to="/events">Events</NavLink>
        </li> */}
        <li className="nav__item">
          <NavLink to="/resources">Resources</NavLink>
        </li>
        <li className="nav__item">
            <NavLink to="/careers">Careers</NavLink>
        </li>
        <li className="nav__item">
            <NavLink to="/about/faq">FAQ</NavLink>
        </li>
        <li className="nav__item">
            <NavLink to="/contact">Contact us</NavLink>
        </li>
        <li className="nav__item nav__item--cta">
            <Button to="/newclient">New client</Button>
        </li>
        
      {/* {auth.isLoggedIn && (
        <li>
            <NavLink to={`/${auth.userId}/places`}>MY PLACES</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/places/new">ADD PLACE</NavLink>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">AUTHENTICATE</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <Button onClick={auth.logout}>LOGOUT</Button>
        </li>
      )} */}
    </ul>
  );
};

export default NavLinks;
