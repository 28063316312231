export const bcbaTeam = [
  {
    name: 'Amy Gable',
    title: 'M.Ed., BCBA, LBS',
    role: 'President',
    image: '/assets/team/clinical_team/Amy-Gable.jpg',
    id: 1,
    about:
      'Amy Gable received her master’s degree in Special Education with a concentration in ABA from East Stroudsburg University. She has been working in the field of Special Education since 1998 as an inclusion teacher, co-teacher, and Emotional Support Teacher throughout the years and as a BCBA since 2011 with experience with ages 2 to 21 years old and receiving supervision in gerontology. Most of her experience has been in the home, community, schools, and working directly with teachers and parents as part of the IEP Team. Her primary responsibilities include quality oversite of the organization as well as working directly with school district trainings and clients of her own. Her clinical interests include, working with teachers and school districts programming for students with special needs and increasing acquisition of learning, AIM program, toilet training, gerontology, PEAK, and inclusion. These are a few interests, however, training exceeds this list.',
  },
  {
    name: 'Nicholas Eckman',
    title: 'M.A., BCBA, LBS',
    role: 'Clinical Director',
    image: '/assets/team/clinical_team/Nicholas-Eckman.jpg',
    id: 2,
    about:
      'Nicholas Eckman, MA, BCBA, LBS is the Clinical Director at ABA Support Services. In 2007 Mr. Eckman received both his master’s degree in Applied Behavior Analysis from Penn State University and became a Board Certified Behavior Analyst.  For over a decade Mr. Eckman has provided behavior analytic services to individuals with Autism, IDD, and other mental health disorders throughout the state of Pennsylvania.  He has developed programs and provided support to individuals who display some of the most severe, intensive, and persistent dangerous behaviors and individuals who require low intensity support in a targeted area. During his career Mr. Eckman has provided instruction to undergraduate students pursing their BCaBA, provided behavior analytic services ranging from individual support to system wide supports - in settings such as public schools, private schools, and inpatient programs, has been an invited speaker at graduate level university programs and has conducted research on pediatric feeding.',
  },
  {
    name: 'Jessica Williamson',
    title: 'MS, BCBA, LBS',
    role: 'Assistant Clinical Director',
    image: '/assets/team/Jessica-Williamson.jpg',
    id: 3,
    about:
      'Jessica Williamson is the Assistant Clinical Director at ABA Support Services. She received her master’s degree in Applied Behavior Analysis from Simmons University in 2016. She has been in the field of ABA for 7 years, where she first began working at a residential facility for individuals diagnosed with developmental disabilities. Jessica has provided applied behavior analysis services in a variety of settings, including schools, residentials, homes, and the communities. She has worked with clients and students aging from 2 to 22. Jessica has worked as a Special Education Teacher in Massachusetts and in Florida. Additionally, she has worked as a behavior analyst at a charter school for individuals diagnosed with autism spectrum disorder ages 14-21, where she provided clinical oversight to multiple classrooms, developed, and created trainings related to behavior analysis, conducted functional behavior assessments for students, provided and created a BCBA trainee supervision program, and conducted research on the evaluation of stimulus prompts and pre-vocational preference assessments. As the Assistant Clinical Director at ABA Support Services, Jessica oversees all cyber school and brick and mortar contracts, she provides onboarding trainings to new staff, develops, and delivers trainings and training plans for all clinical staff, and provides administrative and clinical oversight to BCBA’s and LBS’.',
  },
  {
    name: 'Kathryn Higgins',
    title: 'M.Ed., BCBA, LBS',
    role: 'Supervisor BCBA candidates',
    image: '/assets/team/Kathryn-Higgins.jpg',
    id: 4,
    about:
      'Kate Higgins received her master’s degree in Educational Leadership from Lehigh University and her concentration in ABA from Florida Institute of Technology. She initially started her career in education as an Autistic Support teacher in middle school and eventually moving to lower elementary. After working part time for ABA Support Services since November of 2014, Kate made the decision to begin working as a full time BCBA in January of 2016. Kate loves working with families to develop a plan that best supports the needs of their child, she particularly enjoys working with early learners. She has fulfilled all supervision requirements and enjoys combining her background in education and her experience in ABA to provide oversight and supervision to BCBA candidates.',
  },
  {
    name: 'Dana Anthony',
    title: 'M.Ed., BCBA, LBS',
    role: 'Lead, RBT Supervisor',
    image: '/assets/team/Dana-Anthony.jpg',
    id: 5,
    about:
      'Dana Anthony received her master’s degree in 2014 from Saint Joseph’s University.  She has been working on cases within school districts, the 2:1 pilot program and she has previously overseen RBT/BHT supervision requirements within the agency. Her clinical interests include teaching functional skills, reduction of severe problem behavior and functional analysis, specifically, the PFA (Practical Functional Assessment) and IISCA (Interview Informed Synthesized Contingency Analysis).',
  },
  {
    name: 'Vandana Kotapally',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Vandana-Kotapally.jpg',
    id: 6,
    about:
      "Vandana Kotapally received her master’s degree in Special education from Grand Canyon University.  She has been working in various capacity in home and at school. She has experience working with students from elementary to high school. She has worked with student with autism as well as various other disorders such as multiple disabilities, emotional behavior disorder. Some other clinical interests include generalization of skills to other settings and people and to learn the new ABA interventions to find the best fit and meet my client's needs.",
  },
  // {
  //     name: "Stacey Miller",
  //     title: "M.Ed., BCBA, LBS",
  //     role: "",
  //     image: "/assets/team/Stacey-Miller.jpg",
  //     id: 7,
  //     about: "Stacey L. Miller received his/her master’s degree in Special Education from University of Michigan-Dearborn. She has been working as a BCBA since 2011 in schools, homes and daycare settings, but in the field of behavior analysis as a behavior therapist since 2005. Some of her clinical interests include practical functional assessment and skill-based treatment, acceptance and commitment therapy and school consultations.",
  // },
  {
    name: 'Katie Humen',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Katie-Humen.jpg',
    id: 8,
    about:
      'Katie received her master’s degree in Curriculum & Instruction from Bloomsburg University in 2007.  She has been working with individuals with neurological differences since 2006 and earned her BCBA in 2011. While her clinical experience ranges across the lifespan, her training and experience are focused in early intervention and the treatment of individuals with complex medical and learning histories. Some of her special interests include clinical supervision and coaching, ethics, and trauma-informed treatment.',
  },
  {
    name: 'Cassandra Stroman',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Cassandra-Stroman.jpg',
    id: 9,
    about:
      'Cassie Stroman received her master’s degree in Applied Behavior Analysis from Drexel University and her master’s degree in Criminology/Criminal Justice from Indiana University of Pennsylvania.  She has been working in the field of behavior analysis with children and adolescents since 2013 in the roles of direct therapist, Licensed Behavior Specialist, Board Certified Behavior Analyst, and Lead Board Certified Behavior Analyst. In her time with ABA Support Services, Cassie has developed training for staff and outside service providers related to Individualized Education Plans (IEPs) and functional behavior assessments (FBAs). Some of her clinical interests include autism and comorbid conditions (seizure disorders, genetic disorders, neuroinflammation and autoimmune disorders), applied behavior analysis in the school setting, and applied behavior analysis in the criminal justice system.',
  },
  {
    name: 'Ashleigh Daniell',
    title: 'M.S., BCBA, LBS',
    role: '',
    image: '/assets/team/Ashleigh-Daniell.jpg',
    id: 10,
    about:
      'Ashleigh Daniell received her master’s degree in Psychology specializing in Applied Behavior Analysis from Capella University in 2015.  She has been working in the field of ABA for 10 years. She has clinical experience in schools, in-home, community and early intensive clinic-based programs. Her clinical interests include verbal behavior, PEAK, social skills and daily living skills. She has been employed with ABA Support Services since 2017.',
  },
  {
    name: 'Melissa Rush',
    title: 'M.S., BCBA, LBS',
    role: '',
    image: '/assets/team/Melissa-Rush.jpg',
    id: 11,
    about:
      'Melissa Rush received his/her master’s degree in 2016 from Drexel University.  She has been working as a BCBA in the home environment, starting in early intervention, and now with a variety of ages specializing in autism. Her clinical interests include assisting parents with the learning process of their child’s needs, teaching them behavioral interventions that they can utilize in the home and community, and working with adolescents and young adults. She is pursuing her PhD in behavioral sciences and her research will involve behavioral skills training to assist parents to teach their children skills that could lead to job placement, or meaningful skills that will bring meaning to their lives.',
  },
  {
    name: 'Melissa Weaver',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Melissa-Weaver.jpg',
    id: 12,
    about:
      'Melissa received her master’s degree in Education from Arcadia University.  She has been working in the field of behavior analysis for approximately 4 years. Melissa started out working as an RBT in a clinical setting before going on to earn her BCBA. She has experience working with children between the ages of 18 months to 9 years old. Seeing all the progress her clients make is the most rewarding part of her job! Some of her clinical interests include language acquisition and the treatment of severe problem behavior such as self-injury and aggression.',
  },
  {
    name: 'Jasmine Garmony',
    title: 'M.S., LBS',
    role: '',
    image: '/assets/team/Jasmine-Garmony.jpg',
    id: 13,
    about:
        '',
  },
  {
    name: 'Jessica Bartlett',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Jessica-Bartlett.jpg',
    id: 14,
    about:
      'Jessica Bartlett received her master’s degree in Special Education with a concentration in Applied Behavior Analysis from East Stroudsburg University. She has been implementing and supervising ABA treatment to children who are diagnosed with Autism Spectrum Disorder. She has been employed with ABA Support Services since 2016. Her clinical interests include implementing skill acquisition programs such as PEAK, AIM, AFLS and VBMAPP to teach communication, daily living, and social skills to support those diagnosed with Autism Spectrum Disorder and their families.',
  },
  {
    name: 'Allison Miller',
    title: 'M.S., BCBA, LBS',
    role: '',
    image: '/assets/team/Allison-Miller.jpg',
    id: 15,
    about:
      "Allison Miller received her master's degree in Applied Behavior Analysis from The Chicago School of Professional Psychology.  She has been working at ABA Support Services since 2014. Prior to completing her master's degree, she worked as a Registered Behavior Technician (RBT). Some of her clinical interests include early intervention, verbal behavior approach, and positive behavior support in school setting.",
  },
  {
    name: 'Kendall DmochFagan',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Kendall-DmochFagan.jpg',
    id: 16,
    about:
      'Kendall DmochFagan received her master’s degree in Special Education from Lehigh University. She has been working in the field for several years now and has experience with clients ranging from the ages of 4 to 20 across a variety of settings such as homes, schools, and various community settings. Her primary responsibilities include functional assessment administration, treatment and behavioral programming, supervision of Registered Behavior Technicians (RBTs) and Behavior Analyst (BCBA) candidates, parent and/or caregiver training, and data analysis. Her clinical interests include Autism, comorbid diagnoses, data analysis, behavior change and function-based procedures, as well as school-based treatment.',
  },
  {
    name: 'Maggie Mackintosh',
    title: 'M.A., BCBA, LBS',
    role: '',
    image: '/assets/team/Maggie-Mackintosh.jpg',
    id: 17,
    about:
      'Maggie Mackintosh received her master’s degree in Applied Behavior Analysis from Pennsylvania State University.  She has been working with ABASS since September of 2016 but began working as a TSS/RBT in 2014. She has experience working with teenagers but mainly works with children between the ages 3-9.  Some of her clinical interests include creating task analyses to teach activities of daily living, individualizing, and implementing the PEAK program, and providing supervision and guidance to RBTs.',
  },
  {
    name: 'Chris Elphick',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Chris-Elphick.jpg',
    id: 18,
    about:
      'Chris received his/her master’s degree in Special Education from Ball State University and received his BCBA in 2018. He has been working in the field of special education for over 11 years. Throughout his career he has worked with youth and adults in the school, clinical, community, and group home settings. Some of his clinical interests are treating severe problem behaviors, community-based instruction, and functional communication.',
  },
  {
    name: 'Kristina McLaughlin',
    title: 'M.S., BCBA, LBS',
    role: '',
    image: '/assets/team/Kristina-McLaughlin.jpg',
    id: 19,
    about:
      'Kristina McLaughlin received her master’s degree in Criminal Justice and Behavior Analysis from Saint Joseph’s University.  She has been working with ABA Support Services, LLC since 2018 where she started as a RBT before becoming an LBS and most recently, BCBA. Some of her clinical interests include interventions for challenging behavior and early intervention for young children.',
  },
  {
    name: 'Keri Yeager',
    title: 'M.A., BCBA, LBS',
    role: '',
    image: '/assets/team/Keri-Yeager.jpg',
    id: 20,
    about:
      'Keri Yeager received her master’s degree in Applied Behavior Analysis (ABA) from Arizona State University.  She has been working in the field of ABA for the last 5 years. Starting Summer 2021, she will be leading a Social Skills group for teenagers. Some of her clinical interests include early intervention and teenagers transitioning to adulthood.',
  },
  {
    name: 'Stephanie Shaw',
    title: 'M.A., LBS',
    role: '',
    image: '/assets/team/Stephanie-Shaw.jpg',
    id: 21,
    about:
      'Stephanie Shaw received her master’s degree in Applied Behavior Analysis from Ball State University.  She has been working with individuals with Autism for the past 9 years and has worked in the field of ABA for the past 4 years. Stephanie has experience working with both children and adults. Some of her clinical interests include VB-MAPP and teaching life skills.',
  },
  {
    name: 'Jacqueline Smith',
    title: 'M.Ed., BCBA, LBS',
    role: '',
    image: '/assets/team/Jacqueline-Smith.jpg',
    id: 22,
    about:
      'Jacquelyn Smith received her master’s Degrees in Public Health and Special Education from East Stroudsburg University. She has been working in the behavior analysis field for almost 3 years, starting as a Registered Behavior Technician and now transitioned to a Licensed Behavior Specialist. She is currently working with children ages 18months - 5 years of age; however, is experienced with the ages 1-20 years old. Some of her clinical interests include early intervention, autism spectrum disorder, ESDM, verbal behavior, and organizational behavior management.',
  },
  {
    name: 'Monica Miron',
    title: 'M.Ed., LBS',
    role: '',
    image: '/assets/team/Monica-Miron.jpg',
    id: 23,
    about:
      'Monica Miron received her master’s degree in Special Education from Northern Illinois University. She holds professional licenses/certifications in Illinois and Pennsylvania. She has been teaching and applying ABA in a wide range of settings, such as Montessori classroom, daycare, preschool learning center, elementary, middle schools, high schools, charter schools, homes, and online-virtual settings. One of her interests is in transdisciplinary study of behavior analysis applications to treatment.',
  },
];
export const billingTeam = [
  {
    name: 'Ann Adams',
    role: 'Administrative Director',
    image: '/assets/team/Ann-Adams.jpg',
    id: 1,
    contact: {
      phone: '610-365-8373, ext. 206',
      email: 'aadams@abasupportservices.com',
    },
  },
  {
    name: 'Katie Jacobsen',
    role: 'Credentialing Specialist/Admin Assistant',
    image: '/assets/team/Katie-Jacobsen.jpg',
    id: 2,
    contact: {
      phone: '610-365-8373, ext. 210',
      email: 'kjacobsen@abasupportservices.com',
    },
  },
  {
    name: 'Christian McKoy',
    role: 'Billing Specialist',
    image: '/assets/team/Christian-McKoy.jpg',
    id: 3,
    contact: {
      phone: '610-365-8373, ext. 205',
      email: 'cmckoy@abasupportservices.com',
    },
  },
  {
    name: 'Celeste Robustelli',
    role: 'Billing Specialist',
    image: '/assets/team/Celeste-Robustelli.jpg',
    id: 4,
    contact: {
      phone: '610-365-8373, ext. 212',
      email: 'crobustelli@abasupportservices.com',
    },
  },
];

export const cmTeam = [
  {
    name: 'David Ackerman',
    role: 'Client Services Specialist',
    image: '/assets/team/David-Ackerman.jpg',
    id: 1,
    contact: {
      phone: '610-365-8373, ext. 204',
      email: 'referrals@abasupportservices.com',
    },
  },
  {
    name: 'Ashley Bencsics',
    role: 'Client Services Specialist',
    image: '/assets/team/Ashley-Bencsics.jpg',
    id: 2,
    contact: {
      phone: '610-365-8373, ext. 208',
      email: 'referrals@abasupportservices.com',
    },
  },
  {
    name: 'Ashley Gable',
    role: 'Office Assistant',
    image: '/assets/team/Ashley-Gable.jpg',
    id: 3,
    contact: {
      phone: '610-365-8373, ext. 208',
      email: 'referrals@abasupportservices.com',
    },
  },
  {
    name: 'Carly Boos',
    role: 'Administrative Assistant',
    image: '/assets/team/Carly-Boos.jpg',
    id: 4,
    contact: {
      phone: '610-365-8373, ext. 208',
      email: 'referrals@abasupportservices.com',
    },
  },
];

export const hrTeam = [
  {
    name: 'Rebecca Ahner',
    role: 'HR Assistant',
    image: '/assets/team/Rebecca-Ahner.jpg',
    id: 1,
    contact: {
      phone: '610-365-8373, ext. 217',
      email: 'hr@abasupportservices.com',
    },
  },
  {
    name: 'Raenee Mantoni',
    role: 'HR Assistant',
    image: '/assets/team/Raenee-Mantoni.jpg',
    id: 2,
    contact: {
      phone: '610-365-8373, ext. 207',
      email: 'hr@abasupportservices.com',
    },
  },
];

export const counties = {
  Berks: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [11, 15],
  },
  Carbon: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [23],
  },
  Lehigh: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [1, 5, 11, 14, 15, 16, 20, 21, 22],
  },
  Luzerne: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Monroe: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [10, 14],
  },
  Northampton: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [1, 3, 5, 9, 14, 15, 16, 20, 22],
  },
  Pike: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [10],
  },
  Schuylkill: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [23],
  },
  Wayne: {
    region: 'northeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Bucks: {
    region: 'southeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Montgomery: {
    region: 'southeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Philadelphia: {
    region: 'southeast',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Adams: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Cumberland: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [2, 9, 17, 19],
  },
  Dauphin: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [9, 17, 19],
  },
  Lancaster: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Lebanon: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  York: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [1, 9],
  },
  Perry: {
    region: 'southcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Bradford: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Cameron: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Centre: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [1],
  },
  Clarion: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Clearfield: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Clinton: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [1, 12, 23],
  },
  Columbia: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [23],
  },
  Elk: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Forest: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Huntingdon: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Jefferson: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Juniata: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Lycoming: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [12],
  },
  McKean: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Mifflin: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Montour: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [23],
  },
  Northumberland: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Potter: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Snyder: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Sullivan: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Tioga: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Union: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
  Warren: {
    region: 'northcentral',
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'Circles',
      'Transitions',
      'LifeSmart',
    ],
    bcba: [],
  },
};

export const regions = {
  northeast: {
    counties: [
      'Berks',
      'Carbon',
      'Lehigh',
      'Luzerne',
      'Monroe',
      'Northampton',
      'Pike',
      'Schuylkill',
      'Wayne',
      //'Lackawanna',
      //'Susquehanna',
      //'Wyoming',
    ],
    bcbas: [1, 3, 4, 5, 10, 11, 14, 15, 16, 18, 21, 22, 23],
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Early Behavioral Intensive Clinic Services',
      'Adaptive Living Skills Programs',
      'High Intensity Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way Program',
      'Circles',
      'Transitions',
      'LifeSmart',
      'School District Services',
    ],
  },
  northcentral: {
    counties: [
      'Bradford',
      'Cameron',
      'Centre',
      'Clarion',
      'Clearfield',
      'Clinton',
      'Columbia',
      'Elk',
      'Forest',
      'Huntingdon',
      'Jefferson',
      'Juniata',
      'Lycoming',
      'McKean',
      'Mifflin',
      'Montour',
      'Northumberland',
      'Potter',
      'Snyder',
      'Sullivan',
      'Tioga',
      'Union',
      'Warren',
    ],
    bcbas: [1, 12, 23],
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Adaptive Living Skills Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way Program',
      'School District Services',
    ],
  },
  southcentral: {
    counties: [
      'Adams',
      'Cumberland',
      'Dauphin',
      'Lancaster',
      'Lebanon',
      'York',
      'Perry',
    ],
    bcbas: [1, 2, 9, 17, 19],
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Adaptive Living Skills Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way program',
      'School district services',
    ],
  },
  southeast: {
    counties: ['Bucks', 'Montgomery', 'Philadelphia'],
    bcbas: [6],
    services: [
      'Toilet Training',
      'Communication Programs',
      'Social Skills Programs',
      'Sleep Training',
      'Feeding Programs',
      'AIM Programs',
      'Parent Training',
      'PEAK Programs',
      'Adaptive Living Skills Programs',
      'IEE’s for School Districts',
      'School District Trainings',
      'My Way Program',
      'School District Services',
    ],
  },
  // west: {
  //   counties: ['Allegheny'],
  //   bcbas: [],
  //   services: ['School district services'],
  // },
};

export const processSteps = [
  {
    label: 'Intake Process',
    content:
      'The Intake Process includes a case manager gathering diagnostic evaluations and insurance information followed by completion of an intake.',
  },
  {
    label: 'Assessment Process',
    content:
      'The Assessment Process includes completion of a Functional Behavior Assessment and any skill based assessment to assist with the development of treatment.',
  },
  {
    label: 'Treatment Planning',
    content:
      'Treatment Planning includes the generation of the individualized treatment plan and the creation of any additional programming needed to initiate services.',
  },
  {
    label: 'Service Delivery',
    content:
      'Once the treatment team has reviewed the assessment results and treatment plan, services are able to begin. This usually includes daily or regular services by a direct care staff and clinical oversight by a certified or licensed clinician.',
  },
  {
    label: 'Measuring Outcomes',
    content:
      'Measuring Outcomes includes continued evaluation of data collection on an ongoing basis.',
  },
];

export const insuranceCompanies = [
  {
    name: 'aetna',
    img: '/assets/insurance/aetna.png',
  },
  {
    name: 'AmeriHealth',
    img: '/assets/insurance/amerihealth.png',
  },
  {
    name: 'BlueCross BlueShield',
    img: '/assets/insurance/bcbs.png',
  },
  {
    name: 'Capital BLUE',
    img: '/assets/insurance/capital_blue.png',
  },
  {
    name: 'Cigna',
    img: '/assets/insurance/cigna.png',
  },
  {
    name: 'Geisinger',
    img: '/assets/insurance/geisinger.png',
  },
  {
    name: 'Highmark',
    img: '/assets/insurance/highmark.png',
  },
  {
    name: 'Horizon',
    img: '/assets/insurance/horizon.png',
  },
  {
    name: 'Horizon Blue Cross Blue Shield of NJ',
    img: '/assets/insurance/horizonblue.png',
  },
  {
    name: 'Independence Blue Cross',
    img: '/assets/insurance/ibx.png',
  },
  {
    name: 'Magellan Health',
    img: '/assets/insurance/magellan.png',
  },
  {
    name: 'Medicaid',
    img: '/assets/insurance/medicaid.png',
  },
  {
    name: 'MHNet',
    img: '/assets/insurance/mhnet.png',
  },
  {
    name: 'United Healthcare',
    img: '/assets/insurance/uhc.png',
  },
  {
    name: 'TriCare',
    img: '/assets/insurance/tricare.png',
  },
  {
    name: 'CBH',
    img: '/assets/insurance/cbh.png',
  },
  {
    name: 'CCBH',
    img: '/assets/insurance/ccbh.png',
  },
  {
    name: 'Beacon',
    img: '/assets/insurance/beacon.png',
  },
  {
    name: 'Perform Care',
    img: '/assets/insurance/performcare.png',
  },
];
