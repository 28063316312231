import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import counterReducer from './store/reducers/counter';
// import resultReducer from './store/reducers/result';
import sideDrawerReducer from './store/reducers/sideDrawer';
import countyReducer from './store/reducers/county';
import formReducer from './store/reducers/form';

const rootReducer = combineReducers({
  // ctr: counterReducer,
  // res: resultReducer,
  sdr: sideDrawerReducer,
  cnty: countyReducer,
  form: formReducer,
});

const logger = store => {
  return next => {
    return action => {
      //console.log('[Middleware] Dispatching', action);
      const result = next(action);
      //console.log('[Middleware] next state', store.getState());
      return result;
    }
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(
  logger,
  thunk)));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
