import React from 'react';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Button from '../Button/Button'

import './Testimonial.scss';

const Testimonial = (props) => {
  return (
      <div className='carousel-testimonial'>
        <div className='testimonial-content'>
            
            <div className="testimonial-text"><FormatQuoteIcon className="quote-icon quote-icon-left" /><FormatQuoteIcon className="quote-icon quote-icon-right" />{props.text}</div>
            
        </div>
        <Button to='/testimonials'>Show more</Button>
      </div>
  );
};

export default Testimonial;