import React from 'react';

import './MainFooter.scss';

const MainFooter = () => {
    return (
    <div className="main-footer">
        <div id="footer-1">
            <a href='https://www.bacb.com/' target="_blank" rel="noreferrer"><img src="/assets/partners/BACB-ACE.jpg" alt="BACB" /></a>
        </div>
        <div id="footer-2">
            <a href='http://www.abainpa.com/' target="_blank" rel="noreferrer"><img src="/assets/partners/aba-in-pa.png" alt="ABA in PA" /></a>
        </div>
        <div id="footer-3">
            <a href='https://casproviders.org/' target="_blank" rel="noreferrer"><img src="/assets/partners/casp.jpg" alt="casp" /></a>
        </div>
    </div>);
};

export default MainFooter;