import React from 'react';

import './CopyrightBar.scss';

const CopyrightBar = () => {
  return (
    <div id="copyright">
      <nav>
        <ul className="main-footer__links">
          <li className="main-footer__link">
            <a href="/resources/document/parent_policies">Parent Policies</a>
          </li>
          <li className="main-footer__link">
            <a
              href="/resources/document/aba_guidelines_for_asd"
              target="_blank"
            >
              Treatment Guidelines.
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CopyrightBar;
