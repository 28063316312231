export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADD = 'ADD';
export const SUBTRACT = 'SUBTRACT';
export const STORE_RESULT = 'STORE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';
export const OPEN_SIDE_DRAWER = 'OPEN_SIDE_DRAWER';
export const CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER';
export const SET_ACTIVE_COUNTY = 'SET_ACTIVE_COUNTY';
export const TEXT_INPUT_VALUE_CHANGE = 'TEXT_INPUT_VALUE_CHANGE';
export const TEXT_INPUT_TOUCH_CHANGE = 'TEXT_INPUT_TOUCH_CHANGE';
export const CHECKBOX_VALUE_CHANGE = 'CHECKBOX_VALUE_CHANGE';
export const DATE_VALUE_CHANGE = 'DATE_VALUE_CHANGE';







    // onAdd: () => dispatch({type: actionTypes.ADD, value: 5}),
    // onSubstract: () => dispatch({type: actionTypes.SUBTRACT, value: 5}),
    // onStoreResult: (result) => dispatch({type: actionTypes.STORE_RESULT, value: result}),
    // onDeleteResult: (id) => dispatch({type: actionTypes.DELETE_RESULT, value: id})