import React from 'react';
import { insuranceCompanies } from '../../../data/data';

import './InsuranceGrid.scss';

const InsuranceGrid = (props) => {
  return (
    <div className="page-section__insurance-grid">
      {insuranceCompanies.map((val) => (
        <img key={val.name} src={val.img} alt={val.name} />
      ))}
    </div>
  );
};

export default InsuranceGrid;
