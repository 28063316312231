import * as actionTypes from '../actions/actionTypes';
import zipcodes from 'zipcodes-nrviens';

const initialState = {
  name: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  email: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  participantName: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  participantDoB: {
    //value: new Date(),
    value: null,
    isTouched: false,
    isValid: false,
  },
  phone: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  zipCode: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  county: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  city: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  address: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  language: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  diagnosis: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  enrolledInMedicalAssistance: {
    value: 'No',
    isTouched: true,
    isValid: true,
  },
  insurancePlans: {
    value: 'No',
    isTouched: true,
    isValid: true,
  },
  subject: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  emailBody: {
    value: '',
    isTouched: false,
    isValid: false,
  },
  typeOfService: {
    inHome: false,
    inSchool: false,
    daycare: false,
    other: false,
    isValid: false,
  },
  timeOfService: {
    morning: false,
    afternoon: false,
    evening: false,
    overnights: false,
    weekends: false,
    other: false,
    isValid: false,
  },
  referralSource: {
    new: false,
    transfer: false,
    physician: false,
    peer: false,
    other: false,
    isValid: false,
  },
  typeOfServiceInfo: {
    value: '',
    isTouched: true,
    isValid: true,
  },
  timeOfServiceInfo: {
    value: '',
    isTouched: true,
    isValid: true,
  },
  referralName: {
    value: '',
    isTouched: true,
    isValid: true,
  },
  newClientFormValidity: false,
  generalContactFormValidity: false,
};

const newClientInputIDs = [
  'name',
  'email',
  'participantName',
  'participantDoB',
  'phone',
  'zipCode',
  'county',
  'city',
  'address',
  'language',
  'typeOfService',
  'timeOfService',
  'referralSource',
];
const generalContactInputIDs = ['name', 'email', 'subject', 'emailBody'];

const checkboxChangeHandler = (state, field, id) => {
  let value = !state[field][id];
  let fieldValidity = false;
  const ids = {
    typeOfService: ['inHome', 'inSchool', 'daycare', 'other'],
    timeOfService: [
      'morning',
      'afternoon',
      'evening',
      'overnights',
      'weekends',
      'other',
    ],
    referralSource: ['new', 'transfer', 'physician', 'peer', 'other'],
  };
  for (const inputId in ids[field]) {
    if (ids[field][inputId] !== id) {
      fieldValidity = fieldValidity || state[field][ids[field][inputId]];
    } else {
      fieldValidity = fieldValidity || value;
    }
  }

  const newClientFormIsValid = getNewClientFormIsValid(
    state,
    field,
    fieldValidity
  );
  return {
    ...state,
    [field]: {
      ...state[field],
      [id]: value,
      isValid: fieldValidity,
    },
    newClientFormValidity: newClientFormIsValid,
  };
};

const getNewClientFormIsValid = (state, id, inputValidity) => {
  let newClientFormIsValid = true;

  for (const inputId in newClientInputIDs) {
    if (!state[newClientInputIDs[inputId]]) {
      continue;
    }
    if (newClientInputIDs[inputId] !== id) {
      newClientFormIsValid =
        newClientFormIsValid && state[newClientInputIDs[inputId]].isValid;
    } else {
      newClientFormIsValid = newClientFormIsValid && inputValidity;
    }
  }
  return newClientFormIsValid;
};
const getGeneralFormIsValid = (state, id, inputValidity) => {
  let generalContactFormIsValid = true;
  for (const inputId in generalContactInputIDs) {
    if (!state[generalContactInputIDs[inputId]]) {
      continue;
    }
    if (generalContactInputIDs[inputId] !== id) {
      generalContactFormIsValid =
        generalContactFormIsValid &&
        state[generalContactInputIDs[inputId]].isValid;
    } else {
      generalContactFormIsValid = generalContactFormIsValid && inputValidity;
    }
  }
  return generalContactFormIsValid;
};

const inputTouchHandler = (state, id) => {
  // let newClientFormIsValid = true;
  // let generalContactFormIsValid = true;
  const newClientFormIsValid = getNewClientFormIsValid(
    state,
    id,
    state[id].isValid
  );
  const generalContactFormIsValid = getGeneralFormIsValid(
    state,
    id,
    state[id].isValid
  );
  return {
    ...state,
    [id]: {
      ...state[id],
      isTouched: true,
    },
    newClientFormValidity: newClientFormIsValid,
    generalContactFormValidity: generalContactFormIsValid,
  };
};

const dateChangeHandler = (state, id, value) => {
  if (id === 'participantDoB') {
    return {
      ...state,
      participantDoB: {
        //...state.participantDoB,
        value,
        isTouched: true,
        isValid: true,
      },
    };
  } else return state;
};

const inputChangeHandler = (state, id, value) => {
  let inputValidity;
  if (id === 'email') {
    inputValidity = /^\S+@\S+\.\S+$/.test(value);
  } else if (id === 'zipCode') {
    inputValidity = value.length === 5 ? true : false;
  } else if (id === 'typeOfService') {
    inputValidity = value.length > 0 ? true : false;
  } else if (id === 'timeOfService') {
    inputValidity = value.length > 0 ? true : false;
  }
  // else if (id === 'insurancePlans') {
  //   inputValidity = value.length > 1 ? true : false;
  // }
  else {
    inputValidity = value.length > 1 ? true : false;
  }

  const newClientFormIsValid = getNewClientFormIsValid(
    state,
    id,
    inputValidity
  );
  //console.log(state)
  const generalContactFormIsValid = getGeneralFormIsValid(
    state,
    id,
    inputValidity
  );

  let newState = {
    ...state,
    [id]: {
      value: value,
      isTouched: true,
      isValid: inputValidity,
    },
    newClientFormValidity: newClientFormIsValid,
    generalContactFormValidity: generalContactFormIsValid,
  };

  if (id === 'zipCode' && inputValidity) {
    newState = zipCodeChangeHandler(newState, value);
  }
  return newState;
};

const zipCodeChangeHandler = (state, value) => {
  let city = state.city.value;
  let cityValidity = state.city.isValid;
  let county = state.county.value;
  let countyValidity = state.county.isValid;
  const zipObj = zipcodes.lookup(value);
  //console.log(zipObj);

  if (!!zipObj.county) county = zipObj.county;
  if (zipObj.state && zipObj.state === 'PA') {
    if (!!zipObj.city) {
      city = zipObj.city;
      cityValidity = true;
    }
    if (!!zipObj.county) {
      county = zipObj.county;
      countyValidity = true;
    }
  } else {
    return state;
  }

  let newClientFormIsValid = true;
  if (countyValidity && cityValidity) {
    for (const inputId in newClientInputIDs) {
      if (!state[newClientInputIDs[inputId]]) {
        continue;
      }
      if (
        newClientInputIDs[inputId] !== 'city' &&
        newClientInputIDs[inputId] !== 'county'
      ) {
        newClientFormIsValid =
          newClientFormIsValid && state[newClientInputIDs[inputId]].isValid;
      }
    }
  } else {
    newClientFormIsValid = false;
  }

  return {
    ...state,
    city: {
      value: city,
      isTouched: true,
      isValid: cityValidity,
    },
    county: {
      value: county,
      isTouched: true,
      isValid: countyValidity,
    },
    newClientFormValidity: newClientFormIsValid,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEXT_INPUT_TOUCH_CHANGE:
      return inputTouchHandler(state, action.id);
    case actionTypes.TEXT_INPUT_VALUE_CHANGE:
      return inputChangeHandler(state, action.id, action.value);
    case actionTypes.CHECKBOX_VALUE_CHANGE:
      return checkboxChangeHandler(state, action.field, action.id);
    case actionTypes.DATE_VALUE_CHANGE:
      return dateChangeHandler(state, action.id, action.value);
    default:
      return state;
  }
};

export default reducer;
